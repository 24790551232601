.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

:root {
    --swiper-navigation-color: #ffffff;
    --swiper-pagination-color: #ffffff;
    --swiper-navigation-size: 25px;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.link-app {
    text-decoration: none;
    color: black;
    font-size: 40px;
}

/* SCROLLBAR CHAT MESSAGES */

.chat-messages-container {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.chat-messages-container::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

.chat-messages-container::-webkit-scrollbar-track {
    border-radius: 0px;
    background: transparent;
}

.chat-messages-container::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
    border: 0px solid #fff;
}

.chat-messages-container::-webkit-scrollbar-thumb:hover {
    background: transparent;
}

/* SCROLLBAR CHAT MESSAGES */

/* SCROLLBAR EN TODA LA APP PREDETERMINADA */

/* FOR FIREFOX */
* {
    scrollbar-width: thin;
    scrollbar-color: #307fe2 #c6ccd1;
}
/* FOR FIREFOX */

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

::-webkit-scrollbar-track {
    border-radius: 7px;
    background: #c6ccd1;
}

::-webkit-scrollbar-thumb {
    background: #307fe2;
    border-radius: 7px;
    border: 1px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
    background: #3e7ecc;
}

/* SCROLLBAR EN TODA LA APP PREDETERMINADA */

/* Modificacion input number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.lds-circle {
    transform: translateZ(1px);
}

.lds-circle > img {
    animation: lds-circle 10s infinite;
}

@keyframes lds-circle {
    0% {
        transform: rotateY(3600deg);
        animation-timing-function: cubic-bezier(1, 1, 1, 1);
    }
}

/* FullCalendar */

.fc-toolbar-title {
    color: #3c3c3c;
    font-size: 24px !important;
    font-weight: 400;
}

.fc-toolbar-chunk button {
    border-color: white !important;
}

.fc .fc-button-primary:focus {
    box-shadow: none !important;
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none !important;
}
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
    transform: translateY(0.5px) !important;
}

/* .fc-scrollgrid{

  max-height: 655px !important;
  overflow-y: auto;
  margin-bottom: 20px;
 } */

.fc-daygrid-day-frame {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fc .fc-daygrid-day-frame {
    min-height: 100% !important;
}
.fc-daygrid-day-bg {
    background-color: red !important;
}

.fc-daygrid-day-frame:hover {
    background-color: #307fe2;
    cursor: pointer;
}

.fc-daygrid-day-frame:hover .fc-daygrid-day-number {
    color: white !important;
}

.fc-daygrid-day-frame:hover .fc-daygrid-day-content {
    color: white !important;
}

/* .fc-daygrid-day-frame:hover {
    background-color: #307FE2;
    color: white !important;
  } */

.fc-daygrid-day-frame:hover .fc-daygrid-day-events a {
    background-color: #307fe2 !important;
    border-color: #307fe2 !important;
}

.fc-daygrid-day-frame:hover .fc-event-title {
    color: white !important;
}

.fc-daygrid-day-top {
    display: flex;

    justify-content: center;
    align-items: center;
}

.fc-daygrid-day-events a {
    background-color: white !important;
    border-color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fc-daygrid-day-number {
    color: #3c3c3c;
    font-size: 24px !important;
    font-weight: 400;
}

.fc-event-title {
    color: #3c3c3c;
    font-size: 14px !important;
    font-weight: 400;
}

.fc-button-group button {
    background-color: #307fe2 !important;
    border-color: #307fe2 !important;
    color: white;
    font-size: 14px !important;
    font-weight: 400;
}
.fc-today-button {
    display: none !important;
}

/* deshabilitar dias fullcalendar */

.fc-bg-event {
    background-color: #d6dbdf !important;
    border-color: #d6dbdf !important;
}

.fc-bg-event:hover {
    background-color: #d6dbdf !important;
    border-color: #d6dbdf !important;
}

.fc-bg-event:hover {
    cursor: not-allowed;
}

.fc-bg-event {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 1.8rem;
}

.fc-bg-event div {
    color: #0567a4 !important;
    font-weight: 500 !important;
}

.fc-bg-event:hover .fc-event-title {
    color: #34495e !important;
    font-weight: 500 !important;
}

/* TABLE CHECKOUT BORDER RADIUS */

.bordered {
    border: 2px solid #053465;
    border-collapse: separate !important;
    border-spacing: 0;
}

.bordered {
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
}

.bordered td,
.bordered th {
    border-left: 1px solid #053465;
    border-top: 1px solid #053465;
    padding: 10px;
    text-align: left;
}

.bordered th {
    border-top: none;
}

.bordered td:first-child,
.bordered th:first-child {
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.bordered td:last-child,
.bordered th:last-child {
    border-right: none;
    border-bottom: none;
}

.bordered th:first-child {
    -moz-border-radius: 6px 0 0 0;
    -webkit-border-radius: 6px 0 0 0;
    border-radius: 6px 0 0 0;
}

.bordered th:last-child {
    -moz-border-radius: 0 6px 0 0;
    -webkit-border-radius: 0 6px 0 0;
    border-radius: 0 6px 0 0;
    border: none;
}

.bordered th:only-child {
    -moz-border-radius: 6px 6px 0 0;
    -webkit-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
}

.bordered tr:last-child td:first-child {
    -moz-border-radius: 0 0 0 6px;
    -webkit-border-radius: 0 0 0 6px;
    border-radius: 0 0 0 6px;
}

.bordered tr:last-child td:last-child {
    -moz-border-radius: 0 0 6px 0;
    -webkit-border-radius: 0 0 6px 0;
    border-radius: 0 0 6px 0;
}

/* REACT QUILL IMAGE RESIZE */

.image-react-quill {
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 30rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

/* ----- */

/* Estilos para el contenedor de las flechas de navegación */
.swiper-navigation {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
}

/* Estilos para las flechas de navegación */
.swiper-button-next,
.swiper-button-prev {
    margin-left: 10px;
    color: rgb(255, 255, 255);
    width: 10px;
}

.react-datepicker {
    border: none !important;
    margin-top: 10px !important;
}

.react-datepicker__header {
    background-color: #ffffff !important;
    border-bottom: none !important;
}

.react-datepicker__day-name {
    color: #949494 !important;
}

@media screen and (max-width: 800px) {
    .fc-event-title {
        font-size: 10px !important;
        margin: 0 0 0 0 !important;
    }
}
@media screen and (max-width: 600px) {
    .fc-toolbar-title {
        font-size: 18px !important;
    }
    .fc-daygrid-day-number {
        font-size: 18px !important;
    }
    .fc-event-title {
        font-size: 7px !important;
        margin: 0 0 0 0 !important;
    }
    .fc-button-group button {
        font-size: 10px !important;
    }
}

@media screen and (max-width: 450px) {
    .fc-toolbar-title {
        font-size: 18px !important;
    }
    .fc-daygrid-day-number {
        font-size: 18px !important;
    }
    .fc-event-title {
        font-size: 5px !important;
        margin: 0 0 0 0 !important;
    }
    .fc-button-group button {
        font-size: 7px !important;
    }
}

/* --------    */

/* Logueo Google   */

/* .btn_google div{
  padding: none !important;
 } */

/* Logueo Google   */
