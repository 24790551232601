.react-calendar {
  width: 100%;
  border: 0;
  border-radius: 0.5rem;
}

.accordion-content {
       transition: max-height 0.3s ease-out, padding 0.3s ease;
}

.react-calendar button {
}

.react-calendar__month-view__weekdays {
  display: flex;
}

.react-calendar__tile {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.react-calendar__tile:disabled {
  text-decoration: line-through;
  background-color: rgba(220, 220, 220, 0.422);
  color: rgb(90, 81, 81);
}

react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: rgba(0, 123, 255, 0.697);
}
.react-calendar__tile--now {
  background: white;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: rgba(0, 123, 255, 0.697);
}

.react-calendar__tile--now:enabled:focus {
  background: rgba(0, 123, 255, 0.697);
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: rgba(0, 123, 255, 0.697);
}
.react-calendar__tile--active {
  background: rgba(0, 123, 255, 0.697);
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: rgba(0, 123, 255, 0.697);
}

.hasMinDisponibildiad {
  background-color: orange;
}

/* BLUECOINS BAR CSS */

/* @keyframes right-animate {
  0% {
    width: 100%
  }
  
  100% {
    width: 0%;
  }
} */
.animated-bar {
  animation: right-animate 3s ease infinite alternate;
}