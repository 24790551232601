.swiper-lazy-preloader {
    width: 100%;
    height: 100%;
    background-color: beige;
    left: 0;
    top: 0;
    margin: 0;
    border-radius: 0;
    border: 0;
    transition: none !important;
    animation: none !important;
}